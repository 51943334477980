import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { container, padding, bgImage } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'tailwind.macro'

const Image = props => {
    const { image } = props
    if (!image) return false
    const imageSrc = image.src || image
    
    return (
        <Wrapper>
            <ImageWrapper>
                <PImage
                    key={imageSrc}
                    src={imageSrc}
                >
                    {(src, loading) => {
                        return (
                            <BGImage 
                                image={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </PImage> 
                <PImage
                    key={imageSrc}
                    src={imageSrc}
                >
                    {(src, loading) => {
                        return (
                            <LoadedImage
                                src={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </PImage> 
            </ImageWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`absolute inset-0 w-1/2 h-full`};
    left: initial;

    ${media.tab`
        ${tw`static w-full`};
    `}
`

const ImageWrapper = styled.div`
    ${tw`relative w-full h-full`};
`

const PImage = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;

    ${tw`absolute inset-0 w-full h-full`};
    object-fit: contain;
    object-position: left;

    ${media.tab`
        display: none;
    `}
`

const LoadedImage = styled.img`
    ${tw`mb-16`};
    display: none;
    transition: opacity 0.45s ease;

    ${media.tab`
        display: block;
    `}
`

export default Image
