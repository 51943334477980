import React, { useState, useEffect, createContext } from 'react';
import { isClient } from '../../styles/utils'

export const sliderContext = createContext();

const SliderProvider = (props) => {
	const { Provider } = sliderContext;
	const [active, setActive] = useState(true)
	const [activeCaption, setActiveCaption] = useState(undefined)

	return (
		<Provider
			value={{
                active,
				setActive,
				activeCaption,
				setActiveCaption,
			}}
		>
            {props.children}
		</Provider>
	)
}

export default SliderProvider
