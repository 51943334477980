import React, { useContext, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import { media } from '../../styles/utils'
import { getGlobalData } from '../../utils'
import { Slider } from '../'
import { bgImage } from '../../styles/global'
import { useMouse } from 'react-use'
import { sliderContext } from './SliderProvider'
import { IsMobileContext } from '../../context/IsMobileContext'

import 'swiper/css/swiper.css'

const ImageSlider = props => {
    const { type } = props
    const { global } = getGlobalData()
    const imageSlider = global?.image_slider
    const activeTop = parseInt(global?.starting_top_image)
    const activeBottom = parseInt(global?.starting_bottom_image)
    const { active, setActive, activeCaption, setActiveCaption } = useContext(sliderContext)
    const { isMobile } = useContext(IsMobileContext)
    const ref = React.useRef(null)
    const { elX, elY } = useMouse(ref)

    if (!imageSlider || (isMobile && type === 'outer')) return false

    const renderSlides = position => {
        return imageSlider.map((item, i) => {
            if (!item.image) return

            return (
                <Slide
                    key={i}
                    position={position}
                >
                    <ImageWrapper>
                        <Image src={item.image.sizes.medium2} />
                    </ImageWrapper>

                    <SlideNav>
                        {/* <PrevButton id={'prevSlide'} /> */}
                        <NextButton id={'nextSlide'} />
                    </SlideNav>
                </Slide>
            )
        })
    }

    return (
        <Wrapper
            className={active ? 'active' : 'not-active'}
            ref={ref}
            onMouseLeave={() => {
                setActiveCaption(undefined)
            }}
        >
            <Slider
                position={'top'}
                slides={imageSlider}
                renderSlides={renderSlides}
                activeSlide={activeTop ? activeTop : 0}
            />
            <Slider
                position={'bottom'}
                slides={imageSlider}
                renderSlides={renderSlides}
                activeSlide={activeBottom ? activeBottom : 0}
            />
            {!isMobile && (
                <Caption
                    style={{
                        left: elX && `${elX+16}px`,
                        top: elY && `${elY+4}px`,
                        opacity: elX > 0 ? 1 : 0
                    }}
                    dangerouslySetInnerHTML={{
                        __html: activeCaption && activeCaption,
                    }}
                />
            )}
        </Wrapper>
    )
}

// Shared

const Caption = styled.div`
    position: absolute;
    z-index: 1;
    transform: translateY(-50%);
    line-height: 1;
    /* cursor: se-resize; */
    pointer-events: none;
    user-select: none;
    transition: opacity 0.45s ease;
    opacity: 0;
    white-space: nowrap;

    ${media.tab`
        display: none;
    `}
`

const Wrapper = styled.div`
    ${tw`absolute inset-0 w-1/2 h-full`};
    left: initial;
    overflow: hidden;

    ${media.tab`
        ${tw`static w-full mt-10`};
        flex: 1;
        height: initial;
    `}

    &, * {
        outline: 0;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    &.active {
        transition: opacity 0.45s ease;
        opacity: 1;
        pointer-events: initial;
        /* cursor: pointer; */
    }

    &.not-active {
        opacity: 0;
        pointer-events: none;
        cursor: initial;
    }
`

// Slide

const ImageWrapper = styled.div``
const Image = styled.img``

const Slide = styled.div`
    ${tw`relative flex justify-end items-end w-full h-full bg-white py-0`};
    overflow: hidden;
    outline: 0 !important;

    ${ImageWrapper} {
        ${tw`absolute bottom-0`};
        transform: translateY(50%);

        ${props => {
            if (props.position === 'bottom')
                return css`
                    ${tw`top-0`};
                    bottom: initial;
                    transform: translateY(-50%);
                `
        }}

        ${media.tab`
            ${tw`w-full`};
        `}

        ${Image} {
            width: auto;
            max-width: initial;
            margin-left: auto;
            height: 100vh;

            ${media.tab`
                ${tw`w-full`};
                height: auto;
                margin-left: initial;
            `}
        }
    }
`

const NextButton = styled.div``
const PrevButton = styled.div``

const SlideNav = styled.div`
    ${tw`z-10 absolute inset-0 flex`};
    /* ${NextButton},
    ${PrevButton} {
        ${tw`w-1/2`};
        outline: 0 !important;
    } */

    ${NextButton} {
        ${tw`w-full`};
        outline: 0 !important;
    }
`

export default ImageSlider
