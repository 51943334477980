import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'

const maxWidth = 1680

export const GlobalStyles = createGlobalStyle`
    html {
        font-size: 16px;
    }

    html,
    body {
        ${tw`m-0 p-0 w-full h-full`};

        &, * {
            cursor: se-resize!important;
        }
    }

    body {
        ${tw`font-body leading-none bg-white text-black`};
        font-size: 0.9rem;
        line-height: 1.5;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }

    b, strong {
        font-weight: 700;
    }

    /* ul {
        list-style: disc;
        padding-left: 2rem;
    } */

    .tiny-mce {
        p, ul, h3, h4, hr {
            &:not(:first-child) {
                margin-top: 1em;
            }   
        }

        a {
            text-decoration: underline;
            
            &:hover {
                text-decoration: none;
            }
        }
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }
`

export const container = css`
    ${tw`w-full h-full mx-auto`};
    width: ${maxWidth}px;
    max-width: 100%;
`

export const padding = css`
    ${tw`px-16`};
    box-sizing: border-box;

    ${media.tab`
        ${tw`px-6`};
    `}
`

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`