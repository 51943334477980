import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link, navigate } from 'gatsby'
import NumberFormat from 'react-number-format'
import FullHeight from 'react-div-100vh'
import { isEqual } from 'lodash'
import { useMount, useUnmount } from 'react-use'
import { getCartTotal, getCartCurrencyCode, getCartQuantity } from './utils'

import { media, width } from '../../styles/utils'
import { container, bgImage, padding } from '../../styles/global'
import { getNumberScaleProps, getPriceProps } from '../../utils/shopify'
import { cartContext } from './CartProvider'

const Cart = props => {
    const {
        cartItems,
        setCartItems,
        updateQuantity,
        checkout,
    } = useContext(cartContext)
    // console.log(cartItems)

    const goToCheckout = () => {}

    const removeItemFromCart = item => {
        let updatedCartItems = []
        let removed = false

        cartItems.forEach((cartItem, index) => {
            if (isEqual(cartItem, item) && !removed) {
                removed = true
            } else {
                updatedCartItems.push(cartItem)
            }
        })

        setCartItems(updatedCartItems)
    }

    const renderSubtotals = () => {
        return (
            <Subtotals>
                <Total>
                    Sub Total Amount
                    <Amount>
                        <NumberFormat
                            value={getCartTotal(cartItems)}
                            prefix={'£'}
                            displayType={'text'}
                            {...getNumberScaleProps()}
                        />
                    </Amount>
                </Total>
            </Subtotals>
        )
    }

    const renderCart = () => {
        const cartQuantity = getCartQuantity(cartItems)

        return (
            <>
                <Heading>Review your order</Heading>
                {renderCartItems(
                    cartItems,
                    removeItemFromCart,
                    updateQuantity,
                    renderSubtotals
                )}
            </>
        )
    }

    return (
        <>
            <Wrapper id={'cart-wrapper'}>
                {renderCart()}

                <Footer>
                    <Nav>
                        <Back
                            onClick={() => {
                                navigate(`/store`)
                            }}
                        >
                            {`Back to shop`}
                        </Back>

                        <Checkout
                            href={checkout?.webUrl}
                            target={'_blank'}
                            hasItems={
                                !checkout.processing &&
                                checkout?.webUrl &&
                                cartItems &&
                                cartItems.length > 0
                            }
                        >
                            {'Checkout'}
                        </Checkout>
                    </Nav>
                </Footer>
            </Wrapper>
        </>
    )
}

const renderSelectedOption = (option, i) => {
    if (option.name === 'Title') return
    return (
        <Subheading key={i}>
            {`${option.name}: ${option.value}`}
        </Subheading>
    )
}

export const renderCartItems = (
    cartItems,
    removeItemFromCart,
    updateQuantity,
    renderSubtotals
) => {
    let items
    // console.log(cartItems)

    if (cartItems.length > 0) {
        items = cartItems.map((item, i) => {
            const productImage = item?.selectedVariant?.image?.src
            return (
                <CartItem key={i}>
                    <Link to={`/product/${item.handle}`}>
                        {productImage && (
                            <BGImage image={productImage} />
                        )}
                    </Link>

                    <Info>
                        <Heading>
                            {/* Title */}
                            <Link
                                to={`/product/${item.handle}`}
                            >
                                {item.title}
                            </Link>
                        </Heading>

                        {/* Selected Options */}
                        {item.selectedVariant.selectedOptions && (
                            <SelectedOptions>
                                {item.selectedVariant.selectedOptions.map(
                                    (option, i) => (
                                        renderSelectedOption(option, i)
                                    )
                                )}
                            </SelectedOptions>
                        )}

                        {/* Remove from Cart */}
                        <Remove
                            onClick={() => removeItemFromCart(item)}
                        >
                            Remove
                        </Remove>

                        {/* Subtotals */}
                        <Subtotals>
                            <Total>
                                <Label>Price: </Label>
                                <NumberFormat
                                    value={parseInt(
                                        item.selectedVariant.priceV2.amount
                                    )}
                                    prefix={'£'}
                                    displayType={'text'}
                                    {...getNumberScaleProps()}
                                />
                            </Total>

                            <Total>
                                <Label>Qty: </Label>
                                <NumberFormat
                                    value={item.quantity}
                                    decimalScale={0}
                                    displayType={'text'}
                                />
                            </Total>

                            <Total>
                                <Label>Item Subtotal: </Label>
                                <NumberFormat
                                    value={
                                        parseInt(
                                            item.selectedVariant.priceV2.amount
                                        ) * item.quantity
                                    }
                                    prefix={'£'}
                                    displayType={'text'}
                                    {...getNumberScaleProps()}
                                />
                            </Total>
                        </Subtotals>
                    </Info>
                </CartItem>
            )
        })
    }

    return (
        <CartItems>
            {items}
            {renderSubtotals()}
        </CartItems>
    )
}

// Shared

const Icon = styled.img``
const Heading = styled.div``
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
`

const Subheading = styled.div``
const Description = styled.div``

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    overflow-y: auto;

    > ${Heading} {
        margin-top: auto;
    }
`

const Label = styled.div``
const Total = styled.div``
const Amount = styled.div``
const Subtotals = styled.div`
    padding-top: 1rem;
    ${Total} {
        display: flex;
        justify-content: space-between;
    }
`

// Cart header

const Back = styled.div``
const Close = styled.div``

// Cart Items

const CartItems = styled.div`
    margin-top: 0.75rem;
    width: 100%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 1px solid black;
    }
`

// Cart Item

const Price = styled.div``
const Info = styled.div``
const Remove = styled.div``
const SelectedOptions = styled.div``
const CartItem = styled.div`
    display: flex;
    position: relative;
    padding: 1rem 0;
    border-bottom: 1px solid black;

    ${BGImage} {
        height: 130px;
        width: 130px;
        margin-right: 1rem;
    }

    ${Info} {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        * {
            user-select: none;
        }

        ${Heading} {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &,
            * {
                text-decoration: none;
            }
        }

        ${SelectedOptions} {
            ${Subheading} {
                color: gray;
            }
        }

        /* remove cart item */
        ${Remove} {
            display: flex;
            /* cursor: pointer; */
            margin-bottom: auto;
            text-decoration: underline;
        }

        ${Subtotals} {
            width: 100%;
            margin-top: 1rem;

            > * {
                margin-top: 2px;
            }

            ${Total} {
                justify-content: flex-start;
                ${Label} {
                    margin-right: 4px;
                }
            }
        }
    }
`

// Cart footer

const Nav = styled.div``

const Footer = styled.div`
    margin-top: auto;
    ${Nav} {
        display: flex;
        justify-content: space-between;

        ${Back} {
            /* cursor: pointer; */
        }
    }
`

// Checkout

const Checkout = styled.a`
    text-decoration: none;

    pointer-events: ${props => (props.hasItems ? 'all' : 'none')};
    opacity: ${props => (props.hasItems ? 1 : 0.6)};
    cursor: ${props => (props.hasItems ? 'pointer' : 'default')};
`

export default Cart
