import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import NumberFormat from 'react-number-format'
import tw from 'tailwind.macro'

import {
    container,
    padding,
    bgImage,
    bgIcon,
    hoverState,
} from '../../styles/global'
import { media } from '../../styles/utils'

import { resolvePrice, getNumberScaleProps } from '../../utils/shopify'

const GridItem = props => {
    const { item } = props
    const image = item?.images[0]
    const price = resolvePrice(item)

    const renderImage = image => {
        return (
            <Image key={image?.originalSrc} src={image?.originalSrc}>
                {(src, loading) => {
                    return (
                        <LoadedImage
                            src={src}
                            style={{ opacity: loading ? 0 : 1 }}
                        />
                    )
                }}
            </Image>
        )
    }

    return (
        <Product>
            <ImageWrapper
                className={'image-wrapper'}
                style={{ backgroundColor: !item.images && '#C4C4C4' }}
                to={`/product/${item.handle}`}
            >
                {image && renderImage(image)}
            </ImageWrapper>
            <Info as={Link} to={`/product/${item.handle}`}>
                {item.title && <Heading>{item.title}</Heading>}
                {price && (
                    <Description>
                        <NumberFormat
                            value={price}
                            prefix={'£'}
                            displayType={'text'}
                            {...getNumberScaleProps()}
                        />
                    </Description>
                )}
            </Info>
        </Product>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Product

const ImageWrapper = styled(Link)``
const Product = styled.div`
    ${tw`relative flex flex-col`};
    height: 50vh;
    padding-bottom: 18px;

    ${media.tab`
        display: block;
        height: initial;
    `}

    ${ImageWrapper} {
        flex: 1;
        ${tw`relative flex w-full`};
        /* cursor: pointer; */

        ${media.tab`
            display: block;
        `}

        ${LoadedImage} {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: contain;

            ${media.tab`
                ${tw`static`};
                width: 100%;
                height: auto;
                object-fit: initial;
            `}
        }
    }

    ${Info} {
        ${tw`flex justify-between`};
        margin-top: 23px;
        text-decoration: none;

        /* Title */
        ${Heading} {}

        /* Price */
        ${Description} {
            margin-right: 16px;
        }
    }
`

export default GridItem
