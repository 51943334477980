import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'tailwind.macro'

const Grid = props => {
    const { items } = props

    const renderItems = () => {
        return items.map((item, i) => {
            return (
                <Item
                    className={'grid-item'}
                    key={i}
                >
                    {item}
                </Item>
            )
        })
    }

    return (
        <Wrapper>
            <Items
                className={'grid-items'}
            >
                {items && renderItems()}
            </Items>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`absolute inset-0 w-1/2 h-full`};
    left: initial;
    overflow-y: auto;

    ${media.tab`
        ${tw`static w-full`};
        overflow-y: initial;
    `}
`

const Item = styled.div``

const Items = styled.div`
    ${tw`flex flex-wrap`};

    ${Item} {
        ${tw`w-1/2`};

        &:nth-child(odd) {
            padding-right: ${1/12*100}%;
        }

        &:nth-child(even) {
            padding-left: ${1/12*100}%;
        }
    }
`

export default Grid
