import React, { useState, useEffect, useContext } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import { find, filter, forEach, isEqual, remove } from 'lodash'
import { useMount } from 'react-use'
import Select from 'react-select'
import NumberFormat from 'react-number-format'

import { media } from '../../styles/utils'
import { container, padding, hoverState, bgIcon, button } from '../../styles/global'
import { filterVariantsByOption } from './utils'
import { getGlobalData } from '../../utils'
import { cartContext } from '../Cart/CartProvider'
import { getNumberScaleProps } from '../../utils/shopify'

const ProductForm = (props) => {
    const { productData } = props
    if (!productData) return

    const {
        cartItems,
        setCartItems,
        addCartItem,
        active: cartActive,
        setActive: setCartActive,
    } = useContext(cartContext)

    const [selectedOptions, setSelectedOptions] = useState({})
    const [availableVariants, setAvailableVariants] = useState({})
    const [selectedVariant, setSelectedVariant] = useState(false)
    const [available, setAvailable] = useState(false)
    const variants = productData?.variants
    const options = productData?.options
    // console.log(selectedVariant)

    // console.log(options)

    useEffect(() => {
        // set selected variant
        if (availableVariants.length === 1) {
            setSelectedVariant({
                ...productData,
                selectedVariant: availableVariants[0],
            })
        } else {
            setSelectedVariant(false)
        }
    }, [availableVariants])

    useEffect(() => {
        if (selectedVariant) {
            setAvailable(selectedVariant?.selectedVariant?.available)
        }
    }, [selectedVariant])

    useEffect(() => {
        let matches = variants

        forEach(selectedOptions, (value, key) => {
            matches = filter(matches, (variant) => {
                const matchOption = find(variant.selectedOptions, {
                    name: key,
                    value: value,
                })

                if (matchOption) return variant
            })
        })

        // only one available variant, so set the selected options to match that
        if (matches.length === 1) {
            let theseOptions = {}
            forEach(matches[0]?.selectedOptions, (option, key) => {
                theseOptions[option.name] = option.value
            })

            if (!isEqual(selectedOptions, theseOptions)) {
                setSelectedOptions({
                    ...theseOptions,
                })
            }
        }

        setAvailableVariants(matches)
    }, [selectedOptions])

    const checkDisabled = (option) => {
        // Filter variants by passed option
        const matches = filterVariantsByOption(availableVariants, option)

        if (!matches.length) return true
    }

    const checkSelected = (option) => {
        let match = false

        for (const key in selectedOptions) {
            if (selectedOptions.hasOwnProperty(key)) {
                if (
                    key === option.name &&
                    selectedOptions[key] === option.value
                ) {
                    match = true
                }
            }
        }

        return match
    }

    const handleSelectChange = (optionName, value) => {
        const option = {
            name: optionName,
            value: value,
        }

        // Filter variants by passed option
        const matches = filterVariantsByOption(availableVariants, option)

        if (matches.length) {
            setSelectedOptions({
                ...selectedOptions,
                [option.name]: option.value,
            })
        } else {
            setSelectedOptions({
                [option.name]: option.value,
            })
        }
    }

    const renderOptions = () => {
        const items = options.map((option, i) => {
            if (option.name === 'Title') return
            let selectOptions = []

            option.values.forEach((option) => {
                selectOptions.push({
                    value: option.value,
                    label: option.value,
                })
            })

            return (
                <Select
                    key={i}
                    options={selectOptions}
                    onChange={(item) =>
                        handleSelectChange(option.name, item.value)
                    }
                    placeholder={`Select a ${option.name}`}
                    isSearchable={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            primary: 'white',
                            primary25: 'white',
                            primary50: 'white',
                            primary75: 'white',
                        },
                    })}
                />
            )
        })

        return <Options>{items}</Options>
    }

    const renderPrice = () => {
        let price

        if (!selectedVariant) {
            price = variants[0]?.priceV2?.amount
        } else {
            price = selectedVariant?.selectedVariant?.priceV2?.amount
        }

        return (
            <Price>
                <NumberFormat
                    value={price}
                    prefix={'£'}
                    displayType={'text'}
                    {...getNumberScaleProps()}
                />
            </Price>
        )
    }

    const renderDescription = () => {
        if (!productData.descriptionHtml) return
        return (
            <Description
                className={'description'}
                dangerouslySetInnerHTML={{
                    __html: productData.descriptionHtml,
                }}
            />
        )
    }

    const checkCanAddToCart = () => {
        if (selectedVariant && available) {
            return true
        } else {
            return false
        }
    }

    const checkShowError = () => {
        if (selectedVariant && !available) {
            return true
        } else {
            return false
        }
    }

    const renderAddToCart = () => {
        return (
            <AddToCart
                disabled={!checkCanAddToCart()}
            >
                <Button
                    className={'button add-to-cart'}
                    onClick={() => addCartItem(selectedVariant)}
                >
                    Add to cart
                </Button>
                {checkShowError() && (
                    <Error style={{color: 'red'}}>Out of stock</Error>
                )}
            </AddToCart>
        )
    }

    return (
        <>
            <SelectStyles />
            {renderPrice()}
            {options && renderOptions()}
            {renderDescription()}
            {renderAddToCart()}
        </>
    )
}

// Shared

const Heading = styled.h2``
const Title = styled.div``
const Subheading = styled.h3``

const Info = styled.div``

// Layout

const Container = styled.div``

const Wrapper = styled.div``

// Options

const Options = styled.div``

// Select styles

const SelectStyles = createGlobalStyle`
    .react-select-container {
        margin-top: 1rem;

        &, .react-select__control * {
            border-radius: 0!important;
            box-shadow: none;
        }
        
        .react-select__control {
            border-radius: 0;
            border: 1px solid black;
            border-color: black!important;
        }

        .react-select__menu {
            background: white;
            border: 1px solid black;
            &, * {
                border-radius: 0!important;
                box-shadow: none;
            }
        }

        .react-select__menu-list {
            padding: 0;
        }

        .react-select__option {
            display: flex;
            align-items: center;
            margin: 0;

            &:not(:last-child) {
                border-bottom: 1px solid black;
            }
        }
    }
`

// Price

const Price = styled.div`
    margin-top: 21px;
`

// Description

const Description = styled.div`
    margin-top: 53px;
`

// Error

const Error = styled.div`
    margin-left: 20px;
    color: red;
`


// Buttons (Add to Cart)

const Icon = styled.img``
const Button = styled.div``
const AddToCart = styled.div`
    display: flex;
    width: 100%;
    margin-top: 93px;
    margin-bottom: 55px;

    ${Button} {
        /* cursor: pointer; */
        ${props => {
            if (props.disabled)
                return css`
                    opacity: 0.5;
                    pointer-events: none;
                `
        }}
    }
`

export default ProductForm
