import React from 'react'
import styled from 'styled-components'
import Transition from './src/transition'
import { GlobalStyles } from './src/styles/global'
import tw from 'tailwind.macro'
import { ImageSlider } from './src/components'
import Div100vh from 'react-div-100vh'
import CartProvider from './src/components/Cart/CartProvider'
import SliderProvider from './src/components/ImageSlider/SliderProvider'
import { IsMobileProvider } from './src/context/IsMobileContext'

export default ({ element, props }) => {
    return (
        <IsMobileProvider>
            <CartProvider>
                <SliderProvider>
                    <Wrapper>
                        <GlobalStyles />
                        {element}
                        <ImageSlider type={'outer'}/>
                    </Wrapper>
                </SliderProvider>
            </CartProvider>
        </IsMobileProvider>
    )
}

const Wrapper = styled(Div100vh)`
    ${tw`relative w-full`};
    /* max-width: 1920px; */
`
