import React, { useState, useEffect, createContext } from 'react';
import { useMount, useList } from 'react-use'
import { isClient } from '../../styles/utils'
import { forEach, findIndex } from 'lodash'
import { SHOPIFY_STOREFRONT_ACCESS_TOKEN, GATSBY_SHOPIFY_DOMAIN } from '../../constants'
import { client } from '../../utils/shopify'

export const cartContext = createContext();

const CartProvider = (props) => {
	const { Provider } = cartContext;
	const [active, setActive] = useState(false)
	const [checkout, setCheckout] = useState(false)
	const [checkoutProcessing, setCheckoutProcessing] = useState(false)
	const [cartItems, {set: setCartItems, push, updateAt, removeAt}] = useList([])

	useMount(() => {
		if (isClient()) {
			const cart = localStorage.cart

			if (cart) {
				setCartItems(JSON.parse(cart))
			}
		}
	})

	useEffect(() => { 
		localStorage.cart = JSON.stringify(cartItems)
	}, [cartItems])

	const addCartItem = (item) => {
		const exists = findIndex(cartItems, (o) => {
				return o.selectedVariant.id == item.selectedVariant.id
		})
		
		if (exists > -1) {
			const existingItem = cartItems[exists];

			let updatedItem = {
				...existingItem,
				quantity: existingItem.quantity + 1
			}

			updateAt(exists, updatedItem)
		} else {
			let newItem = {
				...item,
				quantity: 1
			}

			push(newItem)
		}

        if (!active) setActive(true)
	}
	
	const updateQuantity = (item, quantity) => {
		const exists = findIndex(cartItems, (o) => {
			return o.selectedVariant.id == item.selectedVariant.id
		})
		
		if (exists > -1) {
			const existingItem = cartItems[exists];

			let updatedItem = {
				...existingItem,
				quantity: quantity
			}

			if (quantity <= 0) {
				removeAt(exists)
			} else {
				updateAt(exists, updatedItem)
			}
		} 
	}

	useEffect(() => {
		let lineItemsToAdd = [];

        cartItems.forEach((item, i) => {
			if (!item.selectedVariant.id || !item.quantity) return;

			console.log(item)

            lineItemsToAdd.push({
                variantId: item.selectedVariant.id,
                quantity: item.quantity
            })
		})
		
		if (lineItemsToAdd.length) {
			setCheckoutProcessing(true)

			client.checkout.create().then((checkout) => {	
				client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((checkout) => {		
					setCheckoutProcessing(false)
					setCheckout(checkout)
				});
			});
		}
	}, [cartItems])

	return (
		<Provider
			value={{
                active,
                setActive,
                cartItems,
				setCartItems,
				addCartItem,
				updateQuantity,
				checkout: {
					...checkout,
					processing: checkoutProcessing
				}
			}}
		>
            {props.children}
		</Provider>
	)
}

export default CartProvider
