import React, { useState, useEffect, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Swiper from 'react-id-swiper'
import { useMount, useMedia } from 'react-use'
import tw from 'tailwind.macro'

import { IsMobileContext } from '../../context/IsMobileContext'
import { sliderContext } from '../ImageSlider/SliderProvider'
import { isClient } from '../../utils'
import { media } from '../../styles/utils'
import { container, padding, hoverState } from '../../styles/global'

const Slider = props => {
    const { slides, position } = props
    const [activeIndex, updateActiveIndex] = useState(null)
    const [swiper, updateSwiper] = useState(null)
    const canLoop = slides && slides.length > 1
    const { isMobile } = useContext(IsMobileContext)
    const { activeCaption, setActiveCaption } = useContext(sliderContext)

    const swiperParams = {
        getSwiper: updateSwiper,
        loop: canLoop,
        effect: false,
        speed: 0,
        noSwiping: true,
        initialSlide: props.activeSlide ? props.activeSlide - 1 : 0,
    }

    useMount(() => {
        onSlideChange()
    })

    useEffect(() => {
        if (swiper) {
            updateActiveIndex(swiper ? swiper.realIndex : 0)
            swiper.on('slideChange', onSlideChange)
            props.onSliderMount && props.onSliderMount(swiper)
        }
    }, [swiper])

    useEffect(() => {
        if (!props.activeSlide || !swiper) return
        swiper.slideTo(props.activeSlide)
    }, [props.activeSlide])

    const onSlideChange = () => {
        updateActiveIndex(swiper ? swiper.realIndex : 0)
    }

    useEffect(() => {
        setActiveCaption(slides[activeIndex]?.caption)
    }, [activeIndex])

    const nextSlide = () => {
        if (!swiper) return
        swiper.slideNext()
    }

    const prevSlide = () => {
        if (!swiper) return
        swiper.slidePrev()
    }

    return (
        <Wrapper
            position={position}
            className={position}
            onMouseEnter={() => {
                if (!isMobile) {
                    setActiveCaption(slides[activeIndex]?.caption)
                    setTimeout(nextSlide, 500)
                }
            }}
            onClick={() => {
                if (isMobile) {
                    nextSlide()
                }
            }}
        >
            <Swiper {...swiperParams}>
                {props.renderSlides && props.renderSlides(position)}
            </Swiper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`absolute inset-x-0 w-full`};
    height: 50%;

    ${media.tab`
        ${tw`static`};
        height: 50%;
    `}

    ${props => {
        if (props.position === 'top')
            return css`
                ${tw`top-0`};
            `
    }}

    ${props => {
        if (props.position === 'bottom')
            return css`
                ${tw`bottom-0`};
            `
    }}

    .swiper-container {
        position: relative;
        width: 100%;
        height: 100%;
    }
`

export default Slider
