import React, { useState, useContext, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'
import { useMount, useMedia } from 'react-use'

import { IsMobileContext } from '../../context/IsMobileContext'
import { MetaTags } from '../'
import { media } from '../../styles/utils'

const Layout = ({ children, meta }) => {
    const isMobile = useContext(IsMobileContext)
    const isMobileDevice = useMedia('(max-width: 1024px)')

    useMount(() => {
        isMobile.setIsMobile(isMobileDevice)
    })

    useEffect(() => {
        isMobile.setIsMobile(isMobileDevice)
    }, [isMobileDevice])

    return (
        <>
            <MetaTags {...meta} />
            <Wrapper style={{ opacity: isMobile.isMobile === 'undefined' ? 0 : 1 }}>
                <Transition>{children}</Transition>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    ${tw`absolute inset-y-0 flex flex-col w-5/12 h-full`};
    left: ${(1 / 24) * 100}%;
    /* max-width: 624px; */
    padding-top: 34px;
    padding-bottom: 90px;
    transition: opacity 0.45s ease;

    ${media.tab`
		${tw`w-full left-0`};
		padding: 16px 16px 90px;
	`}
`

export default Layout
