import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from '../../styles/utils'
import { cartContext } from '../Cart/CartProvider'
import { getCartQuantity } from '../Cart/utils'
import { IsMobileContext } from '../../context/IsMobileContext'
import { getGlobalData } from '../../utils'

const NavItemComp = props => {
    const { link, pageSlug } = props
    if (link?.url.replace(/\//g, '') == pageSlug) return <></>

    return (
        <>
        {link?.type == 'internal' && (
            <NavItem
                as={Link}
                to={link?.url?.includes('home') ? '/' : `${link.url}`}
            >
                {link.title}
            </NavItem>
        )}
        {link?.type == 'external' && (
            <ExternalLink
                as={ExternalLink}
                href={link?.url}
                target="_blank"
            >
                {link?.title}
            </ExternalLink>
        )}
        </>
    )
}

const Nav = props => {
    const { type, slug, nextLink } = props
    const {
        active: cartActive,
        setActive: setCartActive,
        cartItems,
    } = useContext(cartContext)
    const cartQuantity = getCartQuantity(cartItems)
    const { global } = getGlobalData()
    const site_links = global?.site_links
    const shop_links = global?.shop_links_main

    let pageSlug = slug
    if (slug === '/') {
        pageSlug = 'home'
    }

    const renderDefaultNav = () => {
        return site_links.map((item, i) => {
            return (
                <NavItemComp
                    key={i}
                    link={item?.link}
                    pageSlug={pageSlug}
                />
            )
        })
    }

    const renderStoreNav = () => {
        const items = shop_links.map((item, i) => {
            return (
                <NavItemComp
                    key={i}
                    link={item?.link}
                    pageSlug={pageSlug}
                />
            )
        })
        return (
            <>
                {items}
                {pageSlug !== 'store' && (
                    <NavItem as={Link} to={'/store'}>
                        Store
                    </NavItem>
                )}
                {pageSlug !== 'cart' && (
                    <NavItem as={Link} to={'/cart'}>
                        {cartQuantity > 0 && `Cart (${cartQuantity})`}
                        {cartQuantity === 0 && `Cart`}
                    </NavItem>
                )}
                {props.renderNextPage && props.renderNextPage()}
                {props.renderPagination && props.renderPagination()}
                {pageSlug?.includes('product') && (
                    <>
                        {props.nextLink && (
                            <NavItem as={Link} to={props.nextLink}>
                                Next Item
                            </NavItem>
                        )}
                        {!props.nextLink && (
                            <NavItem style={{ color: '#cccccc' }}>
                                Next Item
                            </NavItem>
                        )}
                    </>
                )}
            </>
        )
    }

    return (
        <OuterWrapper>
        {props?.renderShopLinks && props?.renderShopLinks()}
        <Wrapper>
            {type === 'default' && renderDefaultNav()}
            {type === 'store' && renderStoreNav()}
        </Wrapper>
        </OuterWrapper>
    )
}

const ExternalLink = styled.a``

const NavItem = styled.div``

const OuterWrapper = styled.div`
    ${tw`absolute inset-x-0 bottom-0 bg-white`};

    ${media.tab`
        ${tw`fixed`};
        padding: 16px 16px 0;
    `}
`

const Wrapper = styled.div`
    ${tw`flex items-end w-full`};

    ${media.tab`
        ${tw`justify-between`};
    `}

    ${NavItem}, ${ExternalLink}, .nav-pagination, .nav-next-page {
        ${tw`w-1/4`};
        padding: 24px 0;
        text-decoration: none;

        ${media.tab`
            width: auto;
        `}

        &:hover {
            padding-bottom: 48px;

            ${media.tab`
                padding-bottom: 24px;
            `}
        }
    }
`

export default Nav
